var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DrawCanvas", {
    staticClass: "c-canvas",
    class: { "c-active": _vm.isCanvasActivated },
    attrs: { activated: _vm.isCanvasActivated, type: _vm.item.mediaType },
    on: { capture: _vm.capture, download: _vm.download },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }