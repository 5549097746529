var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "wrapper",
      staticClass:
        "c-tablet-viewer c-container d-flex align-center justify-center",
    },
    [
      _c("iframe", {
        key: _vm.src,
        ref: "iframe",
        staticClass: "c-iframe",
        attrs: {
          allowfullscreen: "",
          src: _vm.src,
          title: _vm.title,
          width: `${_vm.contentWidth}px`,
          height: `${_vm.contentHeight}px`,
          sandbox: "allow-modals allow-same-origin allow-scripts allow-popups",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }