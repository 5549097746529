<template>
  <div
    v-show="carouselWidth > 0"
    ref="carousel"
    v-resize="onResize"
    class="c-card-carousel pa-3 swiper"
  >
    <div class="swiper-wrapper" :class="{ 'c-pagination-wrapper': isPaginationActive }">
      <div v-for="item in items" :key="item.id" class="c-card-wrapper swiper-slide">
        <slot name="card" :item="item" />
      </div>
    </div>
    <div ref="pagination" class="swiper-pagination" />
    <div
      v-if="!isReallyMobile"
      ref="prev"
      class="swiper-button-prev"
      :class="{ 'c-mobile': isReallyMobile }"
    >
      <v-btn
        class="c-swiper-button c-swiper-button-prev"
        fab
        small
        @click="$refs.carousel.swiper.slidePrev()"
      >
        <v-icon dark x-large> mdi-chevron-left </v-icon>
      </v-btn>
    </div>
    <div
      v-if="!isReallyMobile"
      ref="next"
      class="swiper-button-next"
      :class="{ 'c-mobile': isReallyMobile }"
    >
      <v-btn
        class="c-swiper-button c-swiper-button-next"
        fab
        small
        @click="$refs.carousel.swiper.slideNext()"
      >
        <v-icon dark x-large> mdi-chevron-right </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import { Mousewheel, Navigation, Pagination } from 'swiper/modules'
// import 'swiper/swiper-bundle.min.css'
import 'swiper/css'
import 'swiper/css/mousewheel'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import mobileMixin from '@/mixins/mobileMixin.js'

Swiper.use([Navigation, Pagination])

export default {
  name: 'CardCarousel',

  components: {},

  mixins: [mobileMixin],

  props: {
    items: {
      type: Array,
      required: true
    },

    cardWidth: {
      type: Number,
      required: false,
      default: 300
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    width: {
      type: Number,
      required: false,
      default: 0
    }
  },

  data: function () {
    return {
      dynamicBulletThreshold: 10,
      observer: true,
      observeParents: true,
      carouselWidth: 0,
      swiperInstance: null,
      resizeTimeout: null,
      swiperOptions: {
        modules: [Mousewheel, Navigation, Pagination],
        // core settings
        cssMode: true,
        observeParents: true,
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 24,
        // mousewheel module
        mousewheel: false,
        // navigation module
        navigation: {
          prevEl: null,
          nextEl: null
        },
        // pagination module
        pagination: {
          el: null,
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 8,
          paginationType: 'bullets',
          renderBullet: function (index, className) {
            return `<span class="${className}">${index + 1}</span>`
          },
          renderCustom: function (swiper, current, total) {
            let customPaginationHtml = ''
            for (let i = 0; i < total; i++) {
              i === current - 1
                ? (customPaginationHtml += `<span class="c-pagination-custom c-pagination-custom-active"></span>`)
                : (customPaginationHtml += `<span class="c-pagination-custom"></span>`)
            }
            return customPaginationHtml
          }
        }
      }
    }
  },

  computed: {
    isReallyMobile() {
      return this.mobileMixin_isReallyMobile
    },

    isPaginationActive() {
      const needsSwiperPagination = !(
        this.swiperInstance?.isBeginning && this.swiperInstance?.isEnd
      )

      const needsPagination =
        needsSwiperPagination || this.items.length > this.swiperInstance.params.slidesPerView

      return needsPagination
    },

    slidesPerView() {
      return 12 / this.cols
    },

    slidesPerViewOld() {
      return (
        Math.floor(this.carouselWidth / (this.cardWidth + this.swiperOptions.spaceBetween)) || 1
      )
    },

    slidesPerGroup() {
      return this.slidesPerView > 1 ? this.slidesPerView - 1 : 1
    },

    cols() {
      const width = this.carouselWidth

      if (width > 2400) return 1 // 12 columns
      if (width > 1800) return 2 // 6 columns
      if (width > 1200) return 3 // 4 columns
      if (width > 900) return 4 // 3 columns
      if (width > 600) return 6 // 2 columns
      if (width > 0) return 12 // 1 column
      return 12
    },

    useDynamicBullets() {
      return this.items.length / this.swiperOptions.slidesPerView > this.dynamicBulletThreshold
    }
  },

  watch: {
    items: {
      immediate: false,
      deep: false,
      handler: function (_newItems, _oldItems) {
        this.$nextTick(() => {
          this.updateSwiper()
          this.goToSlide(0)
        })
      }
    },

    width: {
      immediate: true,
      handler: function (newWidth, _oldWidth) {
        this.carouselWidth = newWidth || this.$refs.carousel?.clientWidth || 0
        if (newWidth > 0) {
          this.resetSlidesPerView()
        }
      }
    }
  },

  created: function () {},

  mounted: function () {
    // update slide options
    this.swiperOptions.slidesPerView = this.slidesPerView
    this.swiperOptions.slidesPerGroup = this.slidesPerGroup

    // update navigation options
    this.swiperOptions.navigation.prevEl = this.$refs.prev
    this.swiperOptions.navigation.nextEl = this.$refs.next

    // update pagination options
    this.swiperOptions.pagination.el = this.$refs.pagination

    this.createSwiper(this.swiperOptions)
  },

  activated: function () {},

  deactivated: function () {},

  beforeDestroy: function () {
    this.destroySwiper()
  },

  methods: {
    /* lifecycle methods */

    createSwiper(options) {
      if (!this.swiperInstance) {
        this.swiperInstance = new Swiper(this.$refs.carousel, options)
      }
    },

    updateSwiper() {
      // full update
      this.swiperInstance?.update() // updates size/number of slides & adds/removes classes

      // navigation update
      this.swiperInstance?.navigation.update() // updates enabled/disabled state of navigation arrows

      // pagination update
      this.swiperInstance?.pagination.update() // updates activation state of pagination dots
    },

    destroySwiper() {
      const destroyInstance = true
      const cleanStyles = true
      this.$nextTick(() => this.swiperInstance?.destroy(destroyInstance, cleanStyles))
    },

    /* event handlers */

    onResize() {
      this.carouselWidth = this.width || this.$refs.carousel.clientWidth

      clearTimeout(this.resizeTimeout)
      this.resizeTimeout = setTimeout(this.resetSlidesPerView, 100)
    },

    /* updaters */

    goToSlide(slideNum) {
      this.swiperInstance?.slideTo(slideNum, 50, true)
    },

    resetSlidesPerView() {
      this.swiperOptions.slidesPerView = this.slidesPerView
      this.swiperOptions.slidesPerGroup = this.slidesPerGroup

      if (this.swiperInstance) {
        if (this.swiperInstance.params.slidesPerView !== this.slidesPerView) {
          this.swiperInstance.params.slidesPerView = this.slidesPerView
          this.swiperInstance.params.slidesPerGroup = this.slidesPerGroup
          // it doesn't appear u can change the pagination after initialization
          // this.swiperInstance.params.dynamicBullets = this.useDynamicBullets
          this.updateSwiper()
        }
      }
    }
  }
}
</script>

<style lang="css" scoped>
.c-card-carousel {
  width: 100%;
}

/* swiper */
.swiper {
  --swiper-navigation-color: var(--v-icon-base);
  --swiper-navigation-size: 44px;
  padding-top: 0px;
}

/* swiper wrapper */
.swiper-wrapper {
  align-items: stretch;
  box-sizing: border-box;
}

/* swiper slides */
.swiper-slide {
  box-sizing: border-box;
  height: unset;
}

/* navigation */
.swiper-button-prev,
.swiper-button-next {
  top: unset;
  bottom: 16px;
  transition: opacity 0.25s;
  opacity: 0.8;
  outline: none;
}
.swiper-button-prev {
  left: 16px; /* allows for swiper wrapper padding */
}
.swiper-button-next {
  right: 16px; /* allow for swiper wrapper padding */
}

.swiper-button-prev.c-mobile,
.swiper-button-next.c-mobile {
  top: 50%;
  bottom: unset;
}
.swiper-button-prev.c-mobile {
  left: 0px;
  right: auto;
}
.swiper-button-next.c-mobile {
  left: auto;
  right: 0px;
}
.c-swiper-button-next:hover .v-icon,
.c-swiper-button-prev:hover .v-icon {
  color: var(--v-accent-base);
  opacity: 1;
  transform: scale(1.25);
}
.swiper-button-disabled {
  opacity: 0.3 !important; /* working - drop prev/next controls? */
}
.swiper-button-next::after,
.swiper-button-prev::after {
  display: none; /* hides native swipper controls */
}
.swiper-button-next.c-mobile::after,
.swiper-button-prev.c-mobile::after {
  display: inline-block; /* shows native swipper controls */
}

/* pagination */
/*
Note:
  Pagination bullets exist in a span
  which scoped css does not affect:

  .swiper-pagination-bullet {}
  .swiper-pagination-bullet-active {}

  However, overriding css vars at the pagination
  parent div seems to do the trick.
*/
.swiper-pagination {
  --swiper-pagination-bullet-size: 24px;
  --swiper-pagination-color: var(--v-accent-base);
  pointer-events: all !important;
  bottom: 16px;
  outline: none;
  padding-left: 40px; /* leave space for prev button */
  padding-right: 40px; /* leave space for next button */
}
.swiper-pagination-bullets-dynamic {
  padding-left: 0px;
  padding-right: 0px;
}
.c-pagination-wrapper {
  padding-bottom: 56px;
}
.c-pagination-custom {
  width: 30px;
  height: 4px;
  display: inline-block;
  background: #000;
  opacity: 0.3;
  margin: 0 5px;
}
.c-pagination-custom-active {
  opacity: 1;
  background-color: #f78e00;
}
</style>
