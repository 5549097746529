<template>
  <ThreeColumnLayout
    class="c-content-layout g-skinny-scrollbars"
    :header="showHeader"
    :left="showLeftPanel"
    :right="showRightPanel"
    :fullscreen="isFullscreen"
    :top="fullscreen ? 0 : top"
    :scroll="true"
  >
    <!-- header -->
    <template #header>
      <AdLeaderboard
        v-model="showAds"
        :hero="isArticle"
        @count="adCount = $event"
      />
    </template>

    <!-- left side -->
    <template #left>
      <PeopleWidget
        v-if="hasAuthors"
        :people="authors"
        :title="$t('content.authors') + '/' + $t('content.advisors')"
      />
      <QuizWidget
        v-if="hasQuiz"
        :qid="quizId"
      />
    </template>

    <!-- middle -->
    <template #middle>
      <slot></slot>
    </template>

    <!-- right side -->
    <template #right>
      <AdWidget
        v-model="showAds"
        zone="side"
        @count="adCount = $event"
      />
    </template>
  </ThreeColumnLayout>
</template>

<script>
import AdLeaderboard from '@/components/ad/AdLeaderboard'
import AdWidget from '@/components/ad/AdWidget'
import PeopleWidget from '@/components/person/PeopleWidget'
import QuizWidget from '@/components/quiz/QuizWidget'
import ThreeColumnLayout from '@/components/base/ThreeColumnLayout'

export default {
  name: 'ContentLayout',

  components: {
    AdLeaderboard,
    AdWidget,
    PeopleWidget,
    QuizWidget,
    ThreeColumnLayout
  },

  props: {
    adFree: {
      type: Boolean,
      required: false,
      default: false
    },

    authors: {
      type: Array,
      required: false,
      default: () => []
    },

    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    },

    params: {
      type: Object,
      required: false,
      default: () => {}
    },

    top: {
      type: Number,
      required: false,
      default: 48
    },

    type: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: function () {
    return {
      // ads
      adCount: 0,
      showAds: !this.adFree,
      // widgets
      showAuthors: true,
      showQuiz: true
    }
  },

  computed: {
    isArticle() {
      return this.type === 'article'
    },

    isFullscreen() {
      return this.fullscreen
    },

    /* layout control */

    showHeader() {
      return this.showTopAds
    },

    showLeftPanel() {
      // Note: ThreeColumnLayout hides left panel when width < 1080px
      return this.hasAuthors || this.hasQuiz
    },

    showRightPanel() {
      // Note: ThreeColumnLayout hides right panel when width < 1080px
      return (this.showAds && this.hasAds) || this.showLeftPanel
    },

    /* widgets */

    hasAuthors() {
      return this.authors?.length > 0
    },

    quizId() {
      // string
      return this.params.quiz
    },

    hasQuiz() {
      return !!this.quizId
    },

    hasAds() {
      return this.adCount > 0
    }
  }
}
</script>

<style lang="scss" scoped>
.c-content-layout {
  --c-content-viewport-height: calc(var(--c-viewport-height) - var(--c-header-height));
}
.c-side-ad {
  &.c-fullscreen {
    display: none;
  }
}
</style>
