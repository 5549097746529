var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ResizableDrawer",
    {
      attrs: {
        color: "background",
        "hide-overlay": "",
        left: "",
        value: _vm.open,
        width: "400",
      },
      on: {
        resizing: function ($event) {
          return _vm.$emit("resizing", $event)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "c-container pa-3" },
        [
          _vm.showAds
            ? _c("AdSpot", {
                staticClass: "c-ads mb-4",
                attrs: { max: 1, stack: "", zone: "drawer" },
                model: {
                  value: _vm.showAds,
                  callback: function ($$v) {
                    _vm.showAds = $$v
                  },
                  expression: "showAds",
                },
              })
            : _vm._e(),
          _vm.item
            ? _c("ContentDetails", {
                attrs: {
                  "data-title": _vm.$t("tour.playerPage.details.title"),
                  "data-intro": _vm.$t("tour.playerPage.details.intro"),
                  item: _vm.item,
                  body: _vm.body,
                },
              })
            : _vm._e(),
          _vm.hasGuidance || _vm.hasCorpus
            ? _c(
                "v-card",
                {
                  staticClass: "c-assistance mx-auto mt-4",
                  attrs: { tile: "" },
                },
                [
                  _c(
                    "v-list",
                    { attrs: { rounded: "" } },
                    [
                      _c("v-subheader", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("content.assistance.title").toUpperCase()
                            ) +
                            " "
                        ),
                      ]),
                      _vm.hasCorpus
                        ? _c(
                            "v-list-item",
                            { staticClass: "my-0" },
                            [
                              _c(
                                "v-list-item-avatar",
                                [
                                  _c("v-img", {
                                    staticClass: "c-avatar",
                                    attrs: {
                                      src: _vm.avatarImage,
                                      contain: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "py-0" },
                                [
                                  _c("v-switch", {
                                    staticClass: "c-switch",
                                    attrs: {
                                      color: "primary",
                                      dense: "",
                                      label: _vm.$t(
                                        "content.assistance.askMelissa"
                                      ),
                                      ripple: false,
                                    },
                                    model: {
                                      value: _vm.showChatbot,
                                      callback: function ($$v) {
                                        _vm.showChatbot = $$v
                                      },
                                      expression: "showChatbot",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.hasGuidance
                        ? _c(
                            "v-list-item",
                            { staticClass: "my-0" },
                            [
                              _c(
                                "v-list-item-icon",
                                { staticClass: "mr-4" },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { size: "40", large: "" } },
                                    [_vm._v(" mdi-compass ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                { staticClass: "py-0" },
                                [
                                  _c("v-switch", {
                                    staticClass: "c-switch",
                                    attrs: {
                                      color: "primary",
                                      dense: "",
                                      label: _vm.$t(
                                        "content.assistance.guideMe"
                                      ),
                                      ripple: false,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$bus.$emit(
                                          "select:guidance",
                                          _vm.showGuidance
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.showGuidance,
                                      callback: function ($$v) {
                                        _vm.showGuidance = $$v
                                      },
                                      expression: "showGuidance",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.hasQuizzes
            ? _c(
                "div",
                _vm._l(_vm.quizzes, function (quiz) {
                  return _c("QuizCard", {
                    key: quiz.id,
                    staticClass: "c-quiz-card pa-4",
                    attrs: { quiz: quiz },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm.hasScenes
            ? _c(
                "v-card",
                { staticClass: "c-scenes mx-auto mt-4", attrs: { tile: "" } },
                [
                  _vm.hasMoreThanOneScene
                    ? _c("SceneList", {
                        staticClass: "mb-4",
                        attrs: { scenes: _vm.scenes },
                        on: {
                          select: function ($event) {
                            return _vm.selectScene($event)
                          },
                          sync: function ($event) {
                            return _vm.syncScene($event)
                          },
                        },
                        model: {
                          value: _vm.selectedSceneId,
                          callback: function ($$v) {
                            _vm.selectedSceneId = $$v
                          },
                          expression: "selectedSceneId",
                        },
                      })
                    : _vm._e(),
                  _vm.hasAnimations
                    ? _c("AnimationList", {
                        attrs: { animations: _vm.animations },
                        on: {
                          select: function ($event) {
                            return _vm.selectAnimation($event)
                          },
                          sync: function ($event) {
                            return _vm.syncAnimations($event)
                          },
                        },
                        model: {
                          value: _vm.selectedAnimationIds,
                          callback: function ($$v) {
                            _vm.selectedAnimationIds = $$v
                          },
                          expression: "selectedAnimationIds",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.hasCorpus
            ? _c(
                "div",
                {
                  staticClass:
                    "c-chatbot flex-column align-start justify-start mt-4",
                },
                [
                  _c("ChatbotChat", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showChatbot,
                        expression: "showChatbot",
                      },
                    ],
                    ref: "chatbot",
                    attrs: { corpus: _vm.corpus },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }