<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="!popup && $vuetify.breakpoint.xsAndDown"
    persistent
    width="80%"
    :max-width="maxWidth"
    min-width="300px"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card
      class="c-dialog-card"
      :class="{ 'c-outlined': outlined }"
    >
      <v-card-title v-if="title" class="px-4">
        <span class="text-h5">{{ title }}</span>
      </v-card-title>

      <v-card-text class="pb-4 px-4">
        <slot>
          {{ text }}
        </slot>
      </v-card-text>

      <v-card-actions
        class="c-dialog-actions pt-0 pb-4"
        :class="{ 'c-outlined pt-4': outlined, 'justify-center': close || confirm }"
      >
        <v-btn
          v-if="!confirm"
          class="c-dialog-btn"
          color="accent"
          rounded
          @click="onClose()"
        >
          {{ closeText || $t('dialog.prompt.close') }}
        </v-btn>

        <v-spacer v-if="!(confirm || close)" />

        <v-btn
          v-if="!close"
          ref="submitButton"
          class="c-dialog-btn"
          :class="{'c-dim' : isMultiAction }"
          color="accent"
          rounded
          @click="onSubmit()"
        >
          {{ submitText || $t('dialog.prompt.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalDialog',

  components: {},

  model: {
    prop: 'show',
    event: 'update:show'
  },

  props: {
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },

    show: {
      type: Boolean,
      required: true
    },

    close: {
      type: Boolean,
      required: false,
      default: false
    },

    confirm: {
      type: Boolean,
      required: false,
      default: false
    },

    popup: {
      type: Boolean,
      required: false,
      default: false
    },

    closeText: {
      type: String,
      required: false,
      default: ''
    },

    submitText: {
      type: String,
      required: false,
      default: ''
    },

    title: {
      type: String,
      required: false,
      default: ''
    },

    text: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: function () {
    return {}
  },

  computed: {
    isMultiAction() {
      return !this.close && !this.confirm
    },

    maxWidth() {
      return this.popup ? '300px' : '80%'
    }
  },

  watch: {
    show: {
      immediate: true,
      handler: function (newShow, _oldShow) {
        this.showDialog = newShow
        if (newShow) {
          // $nextTick doesn't work
          setTimeout(() => {
            this.$refs.submitButton.$el.focus()
          }, 0)
        }
      }
    }
  },

  methods: {
    onClose() {
      this.showDialog = false
      this.$emit('update:show', false)
      this.$emit('close')
    },

    onSubmit() {
      this.showDialog = false
      this.$emit('update:show', false)
      this.$emit('submit')
    }
  }
}
</script>

<style lang="scss" scoped>
.c-dialog-card.c-outlined {
  outline: 1px solid var(--v-text-base);
}
.c-dialog-actions.c-outlined {
  border-top: 1px solid var(--v-text-base);
}
.c-dialog-btn {
  &.c-dim {
    opacity: 0.8;
  }

  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }

  &:focus {
    border: 1px solid var(--v-text-base);
    opacity: 1;
  }
}
</style>
