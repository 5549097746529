var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ThreeColumnLayout", {
    staticClass: "c-content-layout g-skinny-scrollbars",
    attrs: {
      header: _vm.showHeader,
      left: _vm.showLeftPanel,
      right: _vm.showRightPanel,
      fullscreen: _vm.isFullscreen,
      top: _vm.fullscreen ? 0 : _vm.top,
      scroll: true,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "header",
          fn: function () {
            return [
              _c("AdLeaderboard", {
                attrs: { hero: _vm.isArticle },
                on: {
                  count: function ($event) {
                    _vm.adCount = $event
                  },
                },
                model: {
                  value: _vm.showAds,
                  callback: function ($$v) {
                    _vm.showAds = $$v
                  },
                  expression: "showAds",
                },
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "left",
          fn: function () {
            return [
              _vm.hasAuthors
                ? _c("PeopleWidget", {
                    attrs: {
                      people: _vm.authors,
                      title:
                        _vm.$t("content.authors") +
                        "/" +
                        _vm.$t("content.advisors"),
                    },
                  })
                : _vm._e(),
              _vm.hasQuiz
                ? _c("QuizWidget", { attrs: { qid: _vm.quizId } })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
        {
          key: "middle",
          fn: function () {
            return [_vm._t("default")]
          },
          proxy: true,
        },
        {
          key: "right",
          fn: function () {
            return [
              _c("AdWidget", {
                attrs: { zone: "side" },
                on: {
                  count: function ($event) {
                    _vm.adCount = $event
                  },
                },
                model: {
                  value: _vm.showAds,
                  callback: function ($$v) {
                    _vm.showAds = $$v
                  },
                  expression: "showAds",
                },
              }),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }