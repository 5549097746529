var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "container",
      staticClass: "c-book-player c-container",
      class: { "c-fullscreen": _vm.isFullscreen },
    },
    [
      _vm.isLoaded
        ? _c("SlideCarousel", {
            staticClass: "c-carousel",
            attrs: {
              slides: _vm.bookSlides,
              thumbs: _vm.bookThumbs,
              landscape: _vm.isLandscapeMode,
              "background-colour": _vm.isFullscreen ? "black" : "background",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "slide",
                  fn: function (props) {
                    return [
                      _vm.areSlidesImages
                        ? _c("div", { staticClass: "c-image-wrapper" }, [
                            _c("img", {
                              staticClass: "c-image",
                              attrs: { src: props.src },
                            }),
                          ])
                        : _c(
                            "div",
                            {
                              staticClass:
                                "c-iframe-wrapper d-flex align-center justify-center",
                              class: [
                                _vm.bookOrientation === "portrait"
                                  ? "c-portrait"
                                  : "c-landscape",
                              ],
                            },
                            [
                              _c("iframe", {
                                staticClass: "c-iframe",
                                attrs: {
                                  allowfullscreen: "",
                                  src: props.src,
                                  width: `${_vm.contentWidth}px`,
                                  height: `${_vm.contentHeight}px`,
                                },
                              }),
                            ]
                          ),
                    ]
                  },
                },
                {
                  key: "thumb",
                  fn: function (props) {
                    return [
                      _c("v-img", {
                        staticClass: "c-thumb",
                        attrs: {
                          contain: "",
                          height: "100%",
                          width: "100%",
                          src: props.src,
                        },
                      }),
                    ]
                  },
                },
              ],
              null,
              false,
              998816484
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }