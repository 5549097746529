var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-page-layout" },
    [_c("transition", { attrs: { name: "fade" } }, [_c("router-view")], 1)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }