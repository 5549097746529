var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card-actions",
    { staticClass: "justify-space-between" },
    [
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.$t("tooltip.cardAction.view"),
              expression: "$t('tooltip.cardAction.view')",
            },
          ],
          attrs: { icon: "", large: "" },
          on: {
            click: function ($event) {
              return _vm.$emit("view:item")
            },
          },
        },
        [
          _c("v-icon", { attrs: { color: "accent", large: "" } }, [
            _vm._v(" mdi-eye "),
          ]),
        ],
        1
      ),
      _vm.$actions.share
        ? _c(
            "v-btn",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.isShareable
                    ? _vm.$t("tooltip.playerBar.share")
                    : _vm.$t("tooltip.playerBar.noshare"),
                  expression:
                    "isShareable ? $t('tooltip.playerBar.share') : $t('tooltip.playerBar.noshare')",
                },
              ],
              attrs: { color: "accent", icon: "", large: "" },
              on: {
                click: function ($event) {
                  _vm.isShareable && _vm.$emit("share")
                },
              },
            },
            [
              _c("v-icon", { attrs: { large: "" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.isShareable ? "mdi-share-variant" : "mdi-security"
                    ) +
                    " "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.$actions.archive
        ? _c(
            "v-btn",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.$t("tooltip.cardAction.archive"),
                  expression: "$t('tooltip.cardAction.archive')",
                },
              ],
              attrs: { color: "accent", icon: "", large: "" },
              on: {
                click: function ($event) {
                  return _vm.archiveItem(_vm.item)
                },
              },
            },
            [
              _c("v-icon", { attrs: { large: "" } }, [
                _vm._v(" mdi-cloud-download "),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.$t("tooltip.cardAction.info"),
              expression: "$t('tooltip.cardAction.info')",
            },
          ],
          attrs: { color: "accent", icon: "", large: "" },
          on: {
            click: function ($event) {
              return _vm.$emit("view:info")
            },
          },
        },
        [_c("v-icon", { attrs: { large: "" } }, [_vm._v(" mdi-information ")])],
        1
      ),
      _c(
        "v-btn",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.$t("tooltip.cardAction.favourite"),
              expression: "$t('tooltip.cardAction.favourite')",
            },
          ],
          attrs: { color: "red", icon: "", large: "" },
          on: {
            click: function ($event) {
              return _vm.toggleFavourite(_vm.item)
            },
          },
        },
        [
          _c("v-icon", { attrs: { large: "" } }, [
            _vm._v(
              " " +
                _vm._s(_vm.isFavourite ? "mdi-heart" : "mdi-heart-outline") +
                " "
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }