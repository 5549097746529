var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showCookieNotice
    ? _c(
        "v-snackbar",
        {
          staticClass: "c-app-cookies c-snack",
          attrs: {
            "content-class": "c-snack__content",
            app: "",
            bottom: "",
            color: "primary",
            dark: _vm.isDark,
            light: !_vm.isDark,
            timeout: -1,
            value: _vm.showCookieNotice,
            width: "100%",
          },
          scopedSlots: _vm._u(
            [
              {
                key: "action",
                fn: function ({ attrs }) {
                  return [
                    _c(
                      "v-btn",
                      _vm._b(
                        {
                          attrs: { color: "secondary", small: "" },
                          on: { click: _vm.enableCookies },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("app.cookies.enable")) + " "
                        ),
                        _c("v-icon", { attrs: { dark: "", right: "" } }, [
                          _vm._v(" mdi-checkbox-marked-circle "),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      _vm._b(
                        {
                          staticClass: "ml-4",
                          attrs: {
                            color: "secondary",
                            outlined: "",
                            small: "",
                          },
                          on: { click: _vm.disableCookies },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("app.cookies.disable")) + " "
                        ),
                        _c("v-icon", { attrs: { dark: "", right: "" } }, [
                          _vm._v(" mdi-cancel "),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            2402664532
          ),
        },
        [_vm._v(" " + _vm._s(_vm.$t("app.cookies.notice")) + " ")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }