var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-sheet",
    {
      ref: "page",
      staticClass: "c-player-page",
      class: { "c-fullscreen": _vm.isFullscreen },
      attrs: { color: "background" },
    },
    [
      _c("PlayerBar", {
        staticClass: "c-player-bar",
        class: { "c-fullscreen": _vm.isFullscreen },
        attrs: { item: _vm.item },
        on: {
          "activate:canvas": function ($event) {
            _vm.isCanvasActivated = $event
          },
          "toggle:comments": function ($event) {
            _vm.showComments = !_vm.showComments
          },
          close: _vm.onClose,
          fullscreen: function ($event) {
            _vm.isFullscreen = $event
          },
          height: function ($event) {
            _vm.top = $event
          },
          share: function ($event) {
            return _vm.shareItem($event)
          },
        },
        model: {
          value: _vm.showDetails,
          callback: function ($$v) {
            _vm.showDetails = $$v
          },
          expression: "showDetails",
        },
      }),
      _c("PlayerDrawer", {
        staticClass: "c-drawer",
        class: { "c-fullscreen": _vm.isFullscreen },
        attrs: {
          context: _vm.context,
          item: _vm.item,
          open: _vm.showDetails,
          params: _vm.params,
        },
        on: {
          resizing: function ($event) {
            _vm.resizing = $event
          },
        },
      }),
      _c("CommentDrawer", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.commentTarget,
            expression: "commentTarget",
          },
        ],
        staticClass: "c-drawer",
        class: { "c-fullscreen": _vm.isFullscreen },
        attrs: {
          "data-title": _vm.$t("tour.comments.drawer.title"),
          "data-intro": _vm.$t("tour.comments.drawer.intro"),
          comments: _vm.comments,
          "target-id": _vm.commentTarget,
        },
        model: {
          value: _vm.showComments,
          callback: function ($$v) {
            _vm.showComments = $$v
          },
          expression: "showComments",
        },
      }),
      _vm.isLoading
        ? _c("CircularProgress", {
            staticClass: "c-progress",
            attrs: { message: _vm.$t("message.fetchingContent") },
          })
        : [
            _c("div", {
              staticClass: "c-player-bar--spacer",
              class: { "c-fullscreen": _vm.isFullscreen },
            }),
            _vm.isFound
              ? _c(
                  "ContentLayout",
                  {
                    staticClass: "c-layout",
                    attrs: {
                      "ad-free": _vm.isAdFree,
                      authors: _vm.authors,
                      fullscreen: _vm.isFullscreen,
                      params: _vm.params,
                      type: _vm.mediaType,
                      top: _vm.top,
                    },
                  },
                  [
                    _c("ContentPlayer", {
                      attrs: {
                        item: _vm.item,
                        fullscreen: _vm.isFullscreen,
                        params: _vm.params,
                      },
                      on: {
                        context: function ($event) {
                          _vm.context = $event
                        },
                        "error:handled": function ($event) {
                          return _vm.onError(true, $event)
                        },
                        "error:loading": function ($event) {
                          return _vm.onError(false, $event)
                        },
                        multi: function ($event) {
                          _vm.isMultiPage = $event
                        },
                      },
                    }),
                    _vm.resizing
                      ? _c("div", { staticClass: "c-overlay" })
                      : _vm._e(),
                    _c("ContentCanvas", {
                      staticClass: "c-canvas g-skinny-scrollbars",
                      class: { "c-fullscreen": _vm.isFullscreen },
                      attrs: {
                        canvas: _vm.isCanvasActivated,
                        fullscreen: _vm.isFullscreen,
                        item: _vm.item,
                      },
                    }),
                  ],
                  1
                )
              : _c("ErrorMessage", {
                  attrs: {
                    title: _vm.$t("error.ContentNotFoundError.title"),
                    message: _vm.$t("error.ContentNotFoundError.message"),
                  },
                }),
          ],
      _c(
        "ModalDialog",
        {
          attrs: {
            confirm: "",
            popup: "",
            title: _vm.$t("content.notice"),
            "submit-text": _vm.$t("ui.ok"),
          },
          model: {
            value: _vm.showContentModal,
            callback: function ($$v) {
              _vm.showContentModal = $$v
            },
            expression: "showContentModal",
          },
        },
        [_vm._v(" " + _vm._s(_vm.contentTypeDescription) + " ")]
      ),
      _vm.showSharingModal
        ? _c("ShareModal", {
            attrs: { entity: _vm.sharedEntity },
            model: {
              value: _vm.showSharingModal,
              callback: function ($$v) {
                _vm.showSharingModal = $$v
              },
              expression: "showSharingModal",
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }