var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c-home-view" },
    [
      _c(
        "SectionPanels",
        {
          directives: [
            {
              name: "resize",
              rawName: "v-resize",
              value: _vm.onResize,
              expression: "onResize",
            },
          ],
          ref: "homePanels",
          attrs: { depth: _vm.depth, initial: [0, 1, 2] },
        },
        [
          _c("ContentPanel", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFeaturedContent,
                expression: "showFeaturedContent",
              },
            ],
            staticClass: "c-panel",
            attrs: {
              dense: _vm.isViewportMedium,
              items: _vm.showcaseItems,
              draggable: "",
              layouts: ["grid", "carousel"],
              loading: _vm.isContentLoading,
              title: _vm.showcaseTitle,
              width: _vm.viewWidth,
            },
            on: {
              share: function ($event) {
                return _vm.shareItem($event)
              },
              "view:item": function ($event) {
                return _vm.viewItem($event)
              },
              "view:parent": function ($event) {
                return _vm.viewParent($event)
              },
            },
          }),
          _c("PostPanel", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showRecentPosts,
                expression: "showRecentPosts",
              },
            ],
            staticClass: "c-panel",
            attrs: {
              dense: _vm.isViewportMedium,
              posts: _vm.recentPosts,
              draggable: "",
              layouts: ["grid", "carousel"],
              loading: _vm.arePostsLoading,
              title: _vm.$t("home.posts.recent"),
              width: _vm.viewWidth,
            },
            on: {
              share: function ($event) {
                return _vm.sharePost($event)
              },
              "view:post": function ($event) {
                return _vm.viewPost($event)
              },
            },
          }),
          _c("ChannelPanel", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showChannels,
                expression: "showChannels",
              },
            ],
            staticClass: "c-panel",
            attrs: {
              dense: _vm.isViewportMedium,
              channels: _vm.showcaseChannels,
              draggable: "",
              layouts: ["grid", "carousel"],
              loading: _vm.areChannelsLoading,
              title: _vm.$t("home.channels.featured"),
              width: _vm.viewWidth,
            },
            on: {
              share: function ($event) {
                return _vm.shareChannel($event)
              },
              "view:channel": function ($event) {
                return _vm.viewChannel($event)
              },
            },
          }),
          _vm.showCalendar
            ? _c("CalendarPanel", {
                staticClass: "c-panel",
                attrs: {
                  entries: _vm.calendarEntries,
                  loading: _vm.areEntriesLoading,
                  title: _vm.$t("home.today"),
                },
              })
            : _vm._e(),
          _vm.showContentModal
            ? _c("ContentModal", {
                attrs: { item: _vm.item },
                model: {
                  value: _vm.showContentModal,
                  callback: function ($$v) {
                    _vm.showContentModal = $$v
                  },
                  expression: "showContentModal",
                },
              })
            : _vm._e(),
          _vm.showPostModal
            ? _c("PostModal", {
                attrs: { post: _vm.post },
                model: {
                  value: _vm.showPostModal,
                  callback: function ($$v) {
                    _vm.showPostModal = $$v
                  },
                  expression: "showPostModal",
                },
              })
            : _vm._e(),
          _vm.showSharingModal
            ? _c("ShareModal", {
                attrs: { entity: _vm.sharedEntity },
                model: {
                  value: _vm.showSharingModal,
                  callback: function ($$v) {
                    _vm.showSharingModal = $$v
                  },
                  expression: "showSharingModal",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }