var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "c-thread-card", attrs: { flat: "" } },
    [
      _c(
        "v-alert",
        {
          staticClass: "ma-0 mb-2 rounded-xl",
          attrs: { dismissible: "", type: "error" },
          model: {
            value: _vm.showError,
            callback: function ($$v) {
              _vm.showError = $$v
            },
            expression: "showError",
          },
        },
        [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
      ),
      _c(
        "v-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showForm,
              expression: "showForm",
            },
          ],
          staticClass: "c-card mb-2 pa-2 rounded-xl",
          attrs: { outlined: "" },
        },
        [
          _c("CommentForm", {
            attrs: { rich: _vm.rich },
            on: {
              cancel: function ($event) {
                return _vm.onCancel()
              },
              submit: function ($event) {
                return _vm.onSubmit($event)
              },
            },
          }),
        ],
        1
      ),
      _vm._l(_vm.comments, function (comment, index) {
        return _c(
          "div",
          { key: comment.id, staticClass: "c-outer-thread" },
          [
            _vm.debug
              ? _c("div", [_vm._v(" " + _vm._s(comment.id) + " ")])
              : _vm._e(),
            comment
              ? _c("CommentCard", {
                  ref: "activeCard",
                  refInFor: true,
                  staticClass: "mb-2",
                  attrs: { comment: comment, rich: _vm.rich },
                  on: {
                    replies: function ($event) {
                      return _vm.onReplies(comment, index)
                    },
                    reply: function ($event) {
                      return _vm.onReply(comment, index)
                    },
                    delete: function ($event) {
                      return _vm.onDelete(comment)
                    },
                    update: function ($event) {
                      return _vm.onUpdate({ body: $event, comment })
                    },
                  },
                  model: {
                    value: comment.body,
                    callback: function ($$v) {
                      _vm.$set(comment, "body", $$v)
                    },
                    expression: "comment.body",
                  },
                })
              : _vm._e(),
            _vm.activeThread === comment.id
              ? _c("CommentThread", {
                  key: comment.id,
                  staticClass: "c-inner-thread",
                  attrs: {
                    comments: comment.replies,
                    "parent-id": comment.id,
                    rich: _vm.rich,
                    "show-form": _vm.activeForm === comment.id,
                    "show-replies": _vm.activeThread === comment.id,
                    "target-id": _vm.targetId,
                  },
                  on: {
                    close: function ($event) {
                      _vm.activeForm = 0
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }