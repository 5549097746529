var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      staticClass: "c-bottom-appbar",
      attrs: { app: "", bottom: "", height: "76", "hide-on-scroll": "" },
    },
    [
      _c(
        "v-bottom-navigation",
        {
          staticClass: "c-bottom-navigation g-hide-scrollbar pt-1",
          attrs: {
            "background-color": _vm.isDark ? "background" : "#f0f0f0",
            color: "accent",
            grow: "",
            height: "76",
          },
          model: {
            value: _vm.selectedItem,
            callback: function ($$v) {
              _vm.selectedItem = $$v
            },
            expression: "selectedItem",
          },
        },
        _vm._l(_vm.menuItems, function (menuItem) {
          return _c(
            "v-btn",
            {
              key: menuItem.key,
              staticClass: "c-menu-item px-1",
              attrs: {
                "data-title": _vm.menuTitles[menuItem.key],
                "data-intro": _vm.menuIntros[menuItem.key],
                "data-click": "",
                "active-class": "c-selected-item",
                to: _vm.to(menuItem),
                value: menuItem.key,
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.selectItem(menuItem)
                },
              },
            },
            [
              _c("v-icon", { attrs: { "x-large": "" } }, [
                _vm._v(" " + _vm._s(menuItem.icon) + " "),
              ]),
              _c("span", { staticClass: "c-menu-item__title" }, [
                _vm._v(_vm._s(_vm.menuTitles[menuItem.key])),
              ]),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }