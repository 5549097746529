var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    {
      staticClass: "c-article-viewer c-container d-flex",
      class: { "c-fullscreen": _vm.fullscreen },
    },
    [
      _c("iframe", {
        staticClass: "c-iframe mx-auto g-skinny-scrollbars",
        attrs: { allowfullscreen: "", src: _vm.src, title: _vm.title },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }