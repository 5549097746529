var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ResizableDrawer", {
    attrs: {
      color: "background",
      "data-title": _vm.$t("tour.comments.drawer.title"),
      "data-intro": _vm.$t("tour.comments.drawer.intro"),
      "hide-overlay": "",
      right: "",
    },
    on: {
      resizing: function ($event) {
        return _vm.$emit("resizing", $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "div",
              {
                ref: "drawerSlot",
                staticClass: "c-header d-flex align-center",
              },
              [
                _c("v-subheader", { staticClass: "c-title px-3" }, [
                  _vm._v(" " + _vm._s(_vm.$t("comment.header")) + " "),
                ]),
                _c("v-spacer"),
                _c("CloseButton", {
                  attrs: { top: "4px", right: "8px", size: "small" },
                  on: {
                    close: function ($event) {
                      return _vm.$emit("update:show", false)
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "v-alert",
              {
                staticClass: "ma-2 mt-0 rounded-xl",
                attrs: { type: "info" },
                model: {
                  value: _vm.noComments,
                  callback: function ($$v) {
                    _vm.noComments = $$v
                  },
                  expression: "noComments",
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("comment.notices.first")) + " ")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "default",
        fn: function () {
          return [
            _c("CommentThread", {
              staticClass: "ma-2",
              attrs: {
                comments: _vm.comments,
                rich: "",
                "show-form": "",
                "target-id": _vm.targetId,
              },
              on: {
                count: function ($event) {
                  return _vm.$emit(_vm.count)
                },
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.showComments,
      callback: function ($$v) {
        _vm.showComments = $$v
      },
      expression: "showComments",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }