var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "c-app", attrs: { id: "app" } },
    [
      _vm.loading
        ? _c("CircularProgress", {
            staticClass: "c-init",
            attrs: { logo: _vm.orgLogo, message: _vm.loadingMessage },
          })
        : [
            _c(
              "keep-alive",
              { attrs: { include: "AppLayout" } },
              [_c(_vm.layout, { tag: "component" })],
              1
            ),
            _c("AppCookies"),
            _vm.hasTour && _vm.$feature("tours")
              ? _c("AppTour", {
                  ref: "tour",
                  attrs: {
                    dialog: _vm.layout === "AppLayout",
                    tour: _vm.tourName,
                  },
                })
              : _vm._e(),
            _vm.showMeetingPortlet
              ? _c(
                  "v-dialog",
                  {
                    attrs: {
                      "content-class": "c-meeting-dialog",
                      eager: "",
                      fullscreen: _vm.$vuetify.breakpoint.xsAndDown,
                      "overlay-opacity": "0.2",
                      transition: "scale-transition",
                      width: "80%",
                      "max-width": "600px",
                      "min-width": "300px",
                    },
                    on: {
                      "click:outside": function ($event) {
                        _vm.showMeetingPortlet = false
                      },
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "esc", 27, $event.key, [
                            "Esc",
                            "Escape",
                          ])
                        )
                          return null
                        _vm.showMeetingPortlet = false
                      },
                    },
                    model: {
                      value: _vm.showMeetingPortlet,
                      callback: function ($$v) {
                        _vm.showMeetingPortlet = $$v
                      },
                      expression: "showMeetingPortlet",
                    },
                  },
                  [
                    _c("MeetingWindow", {
                      ref: "meetingWindow",
                      attrs: {
                        jwt: _vm.jwt,
                        room: _vm.room,
                        subject: _vm.subject,
                      },
                      model: {
                        value: _vm.showMeetingPortlet,
                        callback: function ($$v) {
                          _vm.showMeetingPortlet = $$v
                        },
                        expression: "showMeetingPortlet",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
      _c("AppUpdate", {
        on: {
          activating: function ($event) {
            _vm.message = _vm.$t("app.activate.message")
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }