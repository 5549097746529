<template>
  <v-card
    class="pa-2"
    :class="center ? 'd-flex justify-center flex-column text-center' : 'd-flex justify-left'"
    elevation="0"
    rounded
  >
    <PersonAvatar
      :person="person"
      :size="size"
    />

    <div class="c-name-block d-flex flex-column">
      <v-card-title
        class="c-person-name pa-0 ma-auto"
        :class="center ? 'text-center' : 'ml-2 text-left'"
      >
        {{ personName }}
      </v-card-title>
      <v-card-subtitle
        v-if="suffixes && personSuffixes"
        class="c-person-suffixes px-2 py-0"
        :class="center ? 'text-center' : 'text-left'"
      >
        {{ personSuffixes }}
      </v-card-subtitle>
    </div>
  </v-card>
</template>

<script>
import PersonAvatar from '@/components/base/PersonAvatar'
import { getImage } from '@/config/sanityConfig'

export default {
  name: 'PersonBanner',

  components: {
    PersonAvatar
  },

  props: {
    person: {
      type: Object,
      required: true
    },

    center: {
      type: Boolean,
      required: false,
      default: false
    },

    clickable: {
      type: Boolean,
      required: false,
      default: false
    },

    colour: {
      type: String,
      required: false,
      default: 'purple'
    },

    size: {
      type: Number,
      required: false,
      default: 72
    },

    suffixes: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: function () {
    return {}
  },

  computed: {
    avatar() {
      return this.person.picture
        ? getImage(this.person.picture).width(this.size).height(this.size).url()
        : this.person.avatar
    },

    personInitials() {
      return (this.person.firstName?.[0] || '?') + (this.person.lastName?.[0] || '?')
    },

    personName() {
      return (
        this.person.displayName ||
        (this.person.honourific
          ? `${this.person.honourific} ${this.person.firstName} ${this.person.lastName}`
          : `${this.person.firstName} ${this.person.lastName}`)
      )
    },

    personSuffixes() {
      return this.person.suffixes?.join(', ') || ''
    }
  }
}
</script>

<style lang="css" scoped>
.c-clickable {
  cursor: pointer;
}
</style>
