var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.player, {
    tag: "component",
    attrs: {
      content: _vm.item,
      fullscreen: _vm.fullscreen,
      params: _vm.params,
    },
    on: {
      context: function ($event) {
        return _vm.$emit("context", $event)
      },
      "error:handled": function ($event) {
        return _vm.$emit("@error:handled", $event)
      },
      "error:loading": function ($event) {
        return _vm.$emit("@error:loading", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }