var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c-chatbot-player d-flex",
      class: [
        _vm.isReady ? "c-splitscreen" : ".c-initializing",
        _vm.isMobile
          ? "c-portrait flex-column justify-start align-center"
          : "c-landscape flex-row justify-center align-start",
      ],
    },
    [
      _c(
        "div",
        { staticClass: "c-left d-flex justify-center align-center" },
        [
          _c("v-img", {
            staticClass: "c-avatar",
            attrs: { src: _vm.avatarImage, contain: "" },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "c-right g-skinny-scrollbars" },
        [
          _c("ChatbotChat", {
            staticClass: "c-chatbot",
            attrs: { corpus: _vm.corpus },
            on: {
              ready: function ($event) {
                _vm.isReady = true
              },
              "error:loading": function ($event) {
                return _vm.$emit("error:loading")
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }