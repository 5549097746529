<template>
  <div class="c-model-player">
    <UnityApp
      v-if="isUnity"
      ref="unityApp"
      :poster="poster"
      :src="src"
      @loaded="isLoaded = true"
      @error="$emit('error:handled', $event)"
    />
    <GLBViewer
      v-if="isGLB"
      ref="viewerApp"
      :cache-size="scenes.length"
      :options="options"
      :poster="poster"
      :src="src"
      @loaded="isLoaded = true"
      @error="$emit('error:loading', $event)"
    />

    <SceneInfo v-show="showInfo" v-model="showInfo" class="c-info" :title="sceneInfo.title">
      {{ sceneInfo.description }}
    </SceneInfo>

    <v-footer
      v-if="!hideControls && isLoaded"
      class="c-footer d-flex mb-1"
      absolute
      color="rgba(0,0,0,0)"
      inset
      padless
      tile
    >
      <div class="d-none d-sm-flex ml-1">
        <v-img
          class="c-invisible d-inline-block"
          contain
          :src="orgLogo"
          max-height="48px"
          max-width="48px"
        />
      </div>
      <v-spacer />
      <div class="d-flex flex-column align-center justify-center">
        <ModelControls
          v-if="isLoaded"
          v-model="showInfo"
          :help="isGLB"
          :rerender="rerender"
          :scenes="scenes"
          :initial-scene-id="initialSceneId"
          :zero-zoom="isGLB"
          @info="sceneInfo = $event"
          @message="sendMessage($event)"
        />
      </div>
      <v-spacer />
      <div class="d-none d-sm-flex mr-1">
        <v-img class="d-inline-block" contain :src="orgLogo" max-height="48px" max-width="48px" />
      </div>
    </v-footer>
  </div>
</template>

<script>
import SceneInfo from '@/players/ModelPlayer/components/SceneInfo'
import ModelControls from '@/players/ModelPlayer/components/ModelControls'
import GLBViewer from './components/GLB/GLBViewer'
import UnityApp from './components/Unity/UnityApp'

export default {
  name: 'ModelPlayer',

  components: {
    SceneInfo,
    ModelControls,
    GLBViewer,
    UnityApp
  },

  props: {
    content: {
      type: Object,
      required: true
    },

    fullscreen: {
      type: Boolean,
      required: false,
      default: false
    },

    params: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  data: function () {
    return {
      isLoaded: false,
      scenes: [],
      hideControls: false,
      options: {},
      rerender: 0,
      sceneInfo: {},
      showInfo: false,
      poster: this.content.thumbnail,
      src: this.content.contentURL
    }
  },

  computed: {
    isGLB() {
      const url = new URL(this.src)
      return url.pathname.endsWith('.glb')
    },

    isUnity() {
      const url = new URL(this.src)
      return url.pathname.endsWith('.js') || url.pathname.endsWith('.json')
    },

    initialSceneId() {
      return this.isGLB
        ? this.src.match(/[^/]+(?=\.glb$)/g)[0]
        : this.scenes.find((scene) => scene.id)?.id || ''
    },

    initialScene() {
      return this.scenes.find((scene) => scene.id === this.initialSceneId)
    },

    locale() {
      return this.$store.state.i18nStore.locale
    },

    orgLogo() {
      return this.$store.state.orgStore.orgLogo
    }
  },

  created: async function () {
    // fetch the (optional) model config file
    try {
      const config = await this.fetchModelConfig()
      console.debug('[ModelPlayer]: model.json=', config)
      this.hideControls = !!config.hideControls
      this.scenes = config.scenes || []
      this.options = config.modelOptions || {}
    } catch (error) {
      console.error('[ModelPlayer]: Error loading model config file.')
    }

    this.$emit('context', {
      sceneId: this.initialSceneId,
      scenes: this.scenes
    })

    this.showInfo = this.initialScene?.[this.locale].description?.length > 0
  },

  methods: {
    async fetchModelConfig() {
      try {
        const modelURL = this.content.contentURL
        const modelPath = this.isGLB
          ? modelURL.substring(0, modelURL.lastIndexOf('/'))
          : modelURL.substring(0, modelURL.lastIndexOf('/Build/'))

        const response = await fetch(
          `/api/tenant/${this.$store.state.tenantStore.tenantKey}/model?path=${modelPath}`
        )
        if (response.ok) {
          return response.json()
        } else if (response.status === 404) {
          return {} // model.json is optional, so no error is returned
        } else {
          throw new Error(response.statusText)
        }
      } catch (error) {
        console.error('[ModelPlayer]: ', error)
        throw error
      }
    },

    sendMessage(message) {
      this.isGLB
        ? this.$refs.viewerApp.sendViewerMessage({ event: message.eventName, param: message.param })
        : this.$refs.unityApp.sendUnityMessage({ event: message.eventName, param: message.param })
    }
  }
}
</script>

<style lang="css" scoped>
.c-model-player {
  position: relative;
  height: calc(var(--c-content-viewport-height));
  height: 100%;
}

.c-footer {
  z-index: 3;
}

.c-invisible {
  visibility: hidden;
}

@media screen and (max-width: 767px) {
  _::-webkit-full-page-media,
  _:future,
  :root .safari_only {
    padding-bottom: 65px;
  }
}
</style>
